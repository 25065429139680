import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Grid2 as Grid,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    Autocomplete,
} from "@mui/material";
import ColorHash from "color-hash";

import globalStyles from "../../globalStyles";
import { AppDispatch, RootState } from "../../configureStore";
import { Page, Pages } from "../../interface/returns";
import { formNames } from "../../utils/returns";
import { Loan } from "../../interface/loan";
import Icon from "../atoms/Icon";
import { SET_ASSOCIATED_PAGE, SET_FORM, SET_IIIS, SET_PAGE, SET_YEAR } from "../../redux/reducer/returns";

interface PageInfoParams {
}

const PageInfo = ({
}: PageInfoParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const colorHash = new ColorHash({
        lightness: 0.5,
        hue: { min: 90, max: 270 },
    });

    const pdfPages = useSelector<RootState, { active: number; count: number; }>(({ returns }) => returns.pdfPages);
    const loan = useSelector<RootState, Loan | undefined>(({ returns }) => returns.loan);
    const page = useSelector<RootState, Page | undefined>(({ returns }) => returns.pdf?.pages[returns.pdfPages.active]);
    const pages = useSelector<RootState, Pages>(({ returns }) => (returns.pdf?.pages || {}));

    return (
        <Box sx={globalStyles.col}>
            <Icon
                name="ArrowLeft"
                color={pdfPages.active === 1 ? "disabled" : "primary"}
                sx={{
                    height: 64,
                    width: 64,
                }}
                iconButton={{
                    id: 'go-left',
                    disabled: pdfPages.active === 1,
                }}
                onClick={() => {
                    window.scrollTo({ top: 0 });
                    if (pdfPages.active !== 1) {
                        dispatch({ type: SET_PAGE, active: pdfPages.active - 1 });
                    }
                }}
            />
            <Typography
                sx={[{ alignSelf: 'center' }, globalStyles.mb2]}
            >
                Page {pdfPages.active} / {pdfPages.count}
            </Typography>
            <Box sx={[globalStyles.flexCenterCenter, globalStyles.col]}>
                <Grid container spacing={2}>
                    <Grid size={24}>
                        <FormControl fullWidth>
                            <InputLabel id="select-input-label">Form Name</InputLabel>
                            <Select
                                onChange={(e: SelectChangeEvent) => {
                                    dispatch({ type: SET_FORM, formName: e.target.value });
                                }}
                                labelId="select-input-label"
                                // displayEmpty
                                value={page?.formName || ''}
                                label="Form Name"
                            >
                                {
                                    formNames.map((fn) => (
                                        <MenuItem key={`form-${fn}`} value={fn}>{fn}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={24}>
                        <Autocomplete
                            disabled={!page?.formName}
                            disablePortal
                            options={Object.values(pages).filter((pg: Page, pageIdx: number) => (page?.pageNumber !== pageIdx + 1) && pg.formName && ((page?.formName && page.formName !== 'Attachment') ? pg.formName === page.formName : true) && pg.pageNumber !== pdfPages.active).map(({ pageNumber: pn, formName: fn }: Page) => `${pn} - ${fn}`)}
                            value={page?.associatedPage || ''}
                            onChange={(_, value) => {
                                dispatch({ type: SET_ASSOCIATED_PAGE, value });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Associated Page"
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={24}>
                        <FormControl fullWidth>
                            <TextField
                                maxRows={1}
                                slotProps={{ htmlInput: { maxLength: 4 } }}
                                label="Tax Year"
                                color="secondary"
                                value={page?.taxYear || ''}
                                onChange={(e) => { dispatch({ type: SET_YEAR, year: e.target.value }); }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={[globalStyles.flexStartCenter, globalStyles.col]}>
                <FormGroup sx={globalStyles.my2}>
                    {([...loan?.borrowers ?? [], ...loan?.businesses ?? []] as any[]).map(({ firstName, lastName, name, irsIncomeId }: any) => (
                        <FormControlLabel
                            key={`check-${irsIncomeId}`}
                            control={(
                                <Checkbox
                                    style={{
                                        color: colorHash.hex(irsIncomeId || ''),
                                    }}
                                    checked={(page?.iiis ?? [])?.includes(irsIncomeId)}
                                    disabled={!page?.formName}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            dispatch({ type: SET_IIIS, iiis: [...(page?.iiis ?? []), irsIncomeId] });
                                        } else {
                                            dispatch({ type: SET_IIIS, iiis: (page?.iiis ?? []).filter((iii) => iii !== irsIncomeId) });
                                        }
                                    }}
                                />
                            )}
                            label={(<Typography style={{ color: colorHash.hex(irsIncomeId || '') }}>{firstName ? `${firstName} ${lastName}` : name}</Typography>)}
                        />
                    ))}
                </FormGroup>
            </Box>
        </Box>
    );
};

export default PageInfo;
