/* eslint-disable no-case-declarations */
import { v4 } from "uuid";
// eslint-disable-next-line import/no-cycle
import { Payload } from ".";
import { AppDispatch } from "../../configureStore";
import { FaxBorrower, FaxCoverLine, LineItems } from "../../interface/fax";
import { LoanRequest } from "../../interface/loan";
import execute from "../http/execute";
import failure from "../http/failure";
import { get, post } from "../http/request";
import success from "../http/success";

const initialState: {
    awaiting: number;
    first: string;
    borrowers: FaxBorrower[];
    lineItems: LineItems;
    removed: string[];
} = {
    awaiting: 0,
    first: '',
    borrowers: [],
    lineItems: {},
    removed: [],
};

export const GET_FAX_AWAITING_REQUEST = 'fax/GET_FAX_AWAITING_REQUEST';
export const GET_FAX_AWAITING_SUCCESS = 'fax/GET_FAX_AWAITING_SUCCESS';
export const GET_FAX_AWAITING_FAILURE = 'fax/GET_FAX_AWAITING_FAILURE';

export const GET_NEXT_BORROWER_REQUEST = 'fax/GET_NEXT_BORROWER_REQUEST';
export const GET_NEXT_BORROWER_SUCCESS = 'fax/GET_NEXT_BORROWER_SUCCESS';
export const GET_NEXT_BORROWER_FAILURE = 'fax/GET_NEXT_BORROWER_FAILURE';

export const SAVE_FAX_PDF_REQUEST = 'fax/SAVE_FAX_PDF_REQUEST';
export const SAVE_FAX_PDF_SUCCESS = 'fax/SAVE_FAX_PDF_SUCCESS';
export const SAVE_FAX_PDF_FAILURE = 'fax/SAVE_FAX_PDF_FAILURE';

export const REMOVE_BORROWER = 'fax/REMOVE_BORROWER';

export const ADD_LINE_ITEM = 'fax/ADD_LINE_ITEM';
export const REMOVE_LINE_ITEM = 'fax/REMOVE_LINE_ITEM';
export const UPDATE_LINE_ITEM = 'fax/UPDATE_LINE_ITEM';
export const ADD_REQUEST_LINE_ITEM = 'fax/ADD_REQUEST_LINE_ITEM';

type ActionParams =
    | { type: typeof GET_FAX_AWAITING_SUCCESS; data: { awaiting: number; first: string; }; }
    | { type: typeof GET_NEXT_BORROWER_SUCCESS; data: FaxBorrower; }
    | { type: typeof ADD_REQUEST_LINE_ITEM; request: LoanRequest; item: FaxCoverLine; sequenceNumber: string; }
    | { type: typeof REMOVE_LINE_ITEM; id: string; }
    | { type: typeof ADD_LINE_ITEM; name?: string; tin?: string; sequenceNumber: string; }
    | { type: typeof REMOVE_BORROWER; sequenceNumber: string; }
    | { type: typeof SAVE_FAX_PDF_SUCCESS; }
    | { type: typeof UPDATE_LINE_ITEM; value: string; keyName: string; id: string; };

// eslint-disable-next-line default-param-last
function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case UPDATE_LINE_ITEM: {
            return {
                ...state,
                lineItems: {
                    ...state.lineItems,
                    [action.id]: {
                        ...state.lineItems[action.id],
                        item: {
                            ...state.lineItems[action.id].item,
                            [action.keyName]: action.value,
                        },
                    },
                },
            };
        }

        case REMOVE_BORROWER: {
            const cloned: LineItems = Object.keys(state.lineItems).reduce((acc, curr) => {
                if (state.lineItems[curr].sequenceNumber === action.sequenceNumber) return acc;
                return {
                    ...acc,
                    [curr]: state.lineItems[curr],
                };
            }, {} as LineItems);

            return {
                ...state,
                removed: [...state.removed, action.sequenceNumber],
                lineItems: cloned,
            };
        }

        case SAVE_FAX_PDF_SUCCESS: {
            return {
                ...initialState,
            };
        }

        case REMOVE_LINE_ITEM: {
            const cloned = structuredClone(state.lineItems);
            delete cloned[action.id];

            return {
                ...state,
                lineItems: cloned,
            };
        }
        case ADD_REQUEST_LINE_ITEM:
            return {
                ...state,
                lineItems: {
                    ...state.lineItems,
                    [action.request.requestId]: {
                        sequenceNumber: action.sequenceNumber,
                        request: action.request,
                        addition: false,
                        item: action.item,
                    },
                },
            };
        case ADD_LINE_ITEM:
            const uuid = v4();
            return {
                ...state,
                lineItems: {
                    ...state.lineItems,
                    [uuid]: {
                        sequenceNumber: action.sequenceNumber,
                        addition: true,
                        item: {
                            name: action.name ?? '',
                            tin: action.tin ?? '',
                            formNumber: '',
                            taxPeriod: '',
                        },
                    },
                },
            };

        case GET_FAX_AWAITING_SUCCESS:
            return {
                ...state,
                awaiting: action.data.awaiting,
                first: action.data.first,
            };

        case GET_NEXT_BORROWER_SUCCESS:
            const byteCharacters = atob(action.data.pdfB64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob: Blob = new Blob([byteArray], { type: 'application/pdf' });
            // eslint-disable-next-line no-underscore-dangle
            const pdfUrl: string = URL.createObjectURL(blob);

            return {
                ...state,
                borrowers: [
                    ...state.borrowers,
                    {
                        ...action.data,
                        pdfB64: pdfUrl,
                    },
                ],
            };

        default: return state;
    }
}

export const getAwaitingFax = (payload: Payload<any>) => (dispatch: AppDispatch) => {
    dispatch({
        type: GET_FAX_AWAITING_REQUEST,
        payload,
    });

    execute(get, '/fax/awaiting', payload.input, undefined, payload.overrideAuth)
        .then(success(dispatch, GET_FAX_AWAITING_SUCCESS, payload))
        .catch(failure(dispatch, GET_FAX_AWAITING_FAILURE, payload));
};

export const getNextBorrower = (payload: Payload<{ last: string; type: string; }>) => (dispatch: AppDispatch) => {
    dispatch({
        type: GET_NEXT_BORROWER_REQUEST,
        payload,
    });

    execute(get, '/fax/next-borrower', payload.input, undefined, payload.overrideAuth)
        .then(success(dispatch, GET_NEXT_BORROWER_SUCCESS, payload))
        .catch(failure(dispatch, GET_NEXT_BORROWER_FAILURE, payload));
};

export const saveFaxPdf = (payload: Payload<{ lineItems: LineItems; borrowers: FaxBorrower[]; removed: string[]; }>) => (dispatch: AppDispatch) => {
    dispatch({
        type: SAVE_FAX_PDF_REQUEST,
        payload,
    });

    execute(post, '/fax/save-pdf', payload.input, { Authorization: `Bearer ${payload.overrideAuth || localStorage.getItem('ACCESS_TOKEN')}` })
        .then(success(dispatch, SAVE_FAX_PDF_SUCCESS, payload))
        .catch(failure(dispatch, SAVE_FAX_PDF_FAILURE, payload));
};

export default reducer;
