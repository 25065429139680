import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { Box, CircularProgress, Modal } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../configureStore";
import { getTurnTime, SET_TEMP_TURN_TIME, setTurnTime } from "../../redux/reducer/ui";
import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";

interface TurnTimeModalParams {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TurnTimeModal = ({
    open,
    setOpen,
}: TurnTimeModalParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const turnTime = useSelector<RootState, DateTime | null>(({ ui }) => (ui.turnTime ? DateTime.fromFormat(ui.turnTime, 'MM/dd/yyyy') : null));
    const loading = useSelector<RootState, boolean>(({ status: { loading: l } }) => l['ui/GET_TURN_TIMES']);
    const setLoading = useSelector<RootState, boolean>(({ status: { loading: l } }) => l['ui/SET_TURN_TIMES']);

    useEffect(() => {
        if (open) dispatch(getTurnTime({}));
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={() => { setOpen(false); }}
        >
            <Box
                sx={[
                    {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    },
                    globalStyles.flexCenterCenter,
                ]}
            >
                {
                    loading ? <CircularProgress /> : (
                        <Box sx={globalStyles.col}>
                            <DatePicker
                                label="Turn Times"
                                value={turnTime}
                                onChange={(newValue: DateTime | null) => {
                                    if (newValue) {
                                        dispatch({ type: SET_TEMP_TURN_TIME, time: newValue.toFormat('MM/dd/yyyy') });
                                    }
                                }}
                            />
                            <Button
                                sx={globalStyles.mt2}
                                variant="contained"
                                loading={setLoading}
                                onClick={() => {
                                    if (turnTime) {
                                        dispatch(setTurnTime({
                                            input: {
                                                current: turnTime.toFormat('MM/dd/yyyy'),
                                            },
                                            onSuccess: () => { setOpen(false); },
                                        }));
                                    }
                                }}
                            >
                                Set Turn Times
                            </Button>
                        </Box>
                    )
                }
            </Box>
        </Modal>
    );
};

export default TurnTimeModal;
