import html2canvas from "html2canvas";
import { OffsetTypes, defaultOff, defaultOffsets } from "./interface/ui";

export function textToClipboard(text: string) {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
}

export const renderUntoScreen = ({
    rendered,
    ref,
    innerRef,
    scaleTo,
    holderName,
    timeout = 200,
    offset = defaultOffsets,
}: {
    rendered: boolean;
    ref: React.RefObject<HTMLDivElement>;
    innerRef: React.RefObject<HTMLDivElement>;
    scaleTo: number;
    holderName: string;
    timeout?: number
    offset?: OffsetTypes;
}) => {
    const useOff: OffsetTypes = {
        yearHolder: {
            ...defaultOff,
            ...offset.yearHolder,
        },
        sigHolder: {
            ...defaultOff,
            ...offset.sigHolder,
        },
        colHolder: {
            ...defaultOff,
            ...offset.colHolder,
        },
        tinHolder: {
            ...defaultOff,
            ...offset.tinHolder,
        },
        ivesHolder: {
            ...defaultOff,
            ...offset.ivesHolder,
        },
    };

    if (rendered) {
        setTimeout(() => {
            if (ref?.current) {
                html2canvas(ref.current, {
                    x: holderName === 'ivesHolder' ? 20 + useOff.yearHolder.x : holderName === 'formnumHolder' ? 10 + useOff.yearHolder.x : holderName === 'yearHolder' ? (ref.current.clientWidth / 2) + 10 + useOff.yearHolder.x : holderName === 'sigHolder' ? 20 + useOff.sigHolder.x : holderName === 'colHolder' ? (ref.current.clientWidth / 2) - 95 + useOff.colHolder.x : ref.current.clientWidth - 285 + useOff.tinHolder.x,
                    y: holderName === 'ivesHolder' ? (ref.current.clientHeight * 0.3) + useOff.yearHolder.y : holderName === 'formnumHolder' ? 25 + useOff.yearHolder.y : holderName === 'yearHolder' ? (ref.current.clientHeight / 2) - 25 + useOff.yearHolder.y : holderName === 'sigHolder' ? ref.current.clientHeight - 170 + useOff.sigHolder.y : holderName === 'colHolder' ? (ref.current.clientHeight / 2) - 25 + useOff.colHolder.y : 110 + useOff.tinHolder.y,
                    width: holderName === 'ivesHolder' ? 200 + useOff.yearHolder.height : holderName === 'formnumHolder' ? 150 + useOff.yearHolder.height : holderName === 'yearHolder' ? 135 + useOff.yearHolder.height : holderName === 'sigHolder' ? 360 + useOff.sigHolder.height : holderName === 'colHolder' ? 80 + useOff.colHolder.height : 130 + useOff.tinHolder.height,
                    height: holderName === 'ivesHolder' ? 60 + useOff.yearHolder.width : holderName === 'formnumHolder' ? 60 + useOff.yearHolder.width : holderName === 'yearHolder' ? 100 + useOff.yearHolder.width : holderName === 'sigHolder' ? 122 + useOff.sigHolder.width : holderName === 'colHolder' ? 100 + useOff.colHolder.width : 60 + useOff.tinHolder.width,
                    logging: false,
                }).then((canvas) => {
                    if (innerRef.current) {
                        const newCan = canvas;
                        try {
                            const holder = document.getElementById(holderName);

                            if (holder) innerRef.current.removeChild(holder);
                        } catch {
                            console.error("Removal failed");
                        }
                        const { width, height } = newCan;
                        newCan.style.width = `${width * scaleTo}px`;
                        newCan.style.height = `${height * scaleTo}px`;
                        newCan.id = holderName;

                        innerRef.current.appendChild(newCan);
                    }
                });
            }
        }, timeout);
    }
};

export const hotKeysConfig: { button: string, action: string; }[] = [
    {
        button: "n",
        action: "Next Request",
    },
    {
        button: "p",
        action: "Stop Waiting",
    },
    {
        button: "t",
        action: "Mark TIN Correct",
    },
    {
        button: "f",
        action: "Mark Column (b) Correct",
    },
    {
        button: "s",
        action: "Mark 8821 Signature Correct",
    },
    {
        button: "c",
        action: "Submit to IRS/Submit Action Needed",
    },
    {
        button: "e",
        action: "Next Error",
    },
    {
        button: "shift+e",
        action: "Previous Error",
    },
    {
        button: "Left Arrow",
        action: "Page Left",
    },
    {
        button: "Right Arrow",
        action: "Page Right",
    },
];

export const randomColor = () => ({
    r: Math.floor(Math.random() * 200) + 24,
    g: Math.floor(Math.random() * 200) + 24,
    b: Math.floor(Math.random() * 200) + 24,
});

export const getTaxPeriodYear = (period: string): string => {
    const nowYear = new Date().getFullYear();
    const nowMonth = new Date().getMonth();
    const tp = period.toLowerCase();
    if (!tp.startsWith('year')) return tp;

    const [_, numStr] = tp.split(' ');
    const num = Number(numStr);
    return String(nowYear - num - (nowMonth > 4 ? 0 : 1));
};

export const priorityList: string[] = [
    'bob.maguire@halcyonsw.com',
    'jim.mcgowan@halcyonsw.com',
    'ahad.khan@halcyonsw.com',
    'eboonin@halcyonsw.com',
];
