import { useEffect, useMemo, useRef, useState } from "react";
import { Page } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import globalStyles from "../../globalStyles";
import { AppDispatch, RootState } from "../../configureStore";
import { SET_IS_4506, SET_PAGE_NUMBER } from "../../redux/reducer/bus";
import { ImageScale } from "../../interface/ui";
import Zoom from "../organisms/Zoom";
import FormNumberSelector from "../organisms/FormNumberSelector";

interface FormPageParams {
    pageNumber: number;
    retryRender: number;
    rendered: boolean;
    setRendered: (_: boolean) => void;
}

const initScale = (): ImageScale => {
    try {
        const arr = JSON.parse(localStorage.getItem('scale') || '{}');
        if (!arr || !arr.tin || !arr.sig) throw new Error();
        return {
            col: 1,
            ...arr,
        };
    } catch {
        return {
            tin: 2,
            sig: 1,
            col: 1,
            year: 1,
            ives: 1.5,
        };
    }
};

const FormPage = ({
    pageNumber,
    retryRender,
    rendered,
    setRendered,
}: FormPageParams) => {
    const dispatch = useDispatch<AppDispatch>();

    const isBiz = useSelector<RootState, boolean>(({ bus: { request } }) => Boolean(request?.isBusiness));
    const list4506 = useSelector<RootState, { [pageNm: number]: boolean; }>(({ bus: { list4506: l } }) => l);
    const instCode = useSelector<RootState, string | undefined>(({ bus: { request } }) => request?.institutionCode);
    const can4506 = useSelector<RootState, boolean>(({ bus: { request }, institutions: { fortyFive } }) => fortyFive.includes(request?.institutionCode || ''));
    const name = useSelector<RootState, string>(({ bus: { request } }) => ((request?.firstName && request.lastName) ? `${request?.firstName || ''} ${request?.lastName || ''}` : ''));
    const tin = useSelector<RootState, string | undefined>(({ bus }) => {
        if (bus.request?.tin && bus.request.tin.length === 9) {
            const result = isBiz ? /(\d{2})(\d{7})/.exec(bus.request.tin) : /(\d{3})(\d{2})(\d{4})/.exec(bus.request.tin);
            if (result?.[0]) return result.splice(1, isBiz ? 3 : 4).join('-');
        }
        return undefined;
    });

    const ref = useRef<HTMLDivElement>(null);

    const initialScale = useMemo(() => initScale(), []);

    const [is4506, setIs4506] = useState<boolean>(false);
    const [tinPage, setTinPage] = useState<number>(0);
    const [sigPage, setSigPage] = useState<number>(0);
    const [colPage, setColPage] = useState<number>(0);
    const [ivesPage, setIvesPage] = useState<number>(0);

    useEffect(() => {
        dispatch({ type: SET_IS_4506, pageNumber: pageNumber - 1, is4506 });
    }, [is4506, pageNumber]);

    useEffect(() => { setIs4506(list4506[pageNumber - 1] || false); }, [pageNumber]);

    useEffect(() => {
        if (!tinPage) {
            setSigPage(0);
        }
    }, [tinPage]);

    useEffect(() => {
        dispatch({
            type: SET_PAGE_NUMBER,
            pageNumber: (tinPage > 0 && sigPage > 0 && (colPage > 0 || !isBiz)) ? tinPage : null,
        });
    }, [tinPage, sigPage, colPage]);

    return (
        <Box sx={[globalStyles.flexCenterCenter, globalStyles.col]}>
            {can4506 && (
                <FormNumberSelector
                    rendered={rendered}
                    is4506={is4506}
                    setIs4506={setIs4506}
                    pageNumber={pageNumber}
                    retryRender={retryRender}
                    pageRef={ref}
                />
            )}
            {is4506 ? (
                <Zoom
                    initialScale={initialScale}
                    page={ivesPage}
                    setPage={setIvesPage}
                    pageNumber={pageNumber}
                    pageRef={ref}
                    title="Halcyon Solutions US I LLC"
                    zoomType="ives"
                    retryRender={retryRender}
                    rendered={rendered}
                    instCode={instCode}
                />
            ) : (
                <>
                    <Zoom
                        initialScale={initialScale}
                        page={tinPage}
                        setPage={setTinPage}
                        pageNumber={pageNumber}
                        pageRef={ref}
                        title={tin}
                        zoomType="tin"
                        retryRender={retryRender}
                        rendered={rendered}
                        instCode={instCode}
                    />
                    {isBiz && (
                        <Zoom
                            tinPage={tinPage}
                            initialScale={initialScale}
                            page={colPage}
                            setPage={setColPage}
                            pageNumber={pageNumber}
                            pageRef={ref}
                            title="Form"
                            zoomType="col"
                            retryRender={retryRender}
                            rendered={rendered}
                            instCode={instCode}
                        />
                    )}
                    <Zoom
                        tinPage={tinPage}
                        initialScale={initialScale}
                        page={sigPage}
                        setPage={setSigPage}
                        pageNumber={pageNumber}
                        pageRef={ref}
                        title={`Signature${isBiz ? '' : ` - ${name}`}`}
                        zoomType="sig"
                        retryRender={retryRender}
                        rendered={rendered}
                        instCode={instCode}
                    />
                </>
            )}
            <div ref={ref} id="form_page">
                <Page
                    onRenderSuccess={() => { setRendered(true); }}
                    pageNumber={pageNumber}
                />
            </div>
        </Box>
    );
};

export default FormPage;
