export interface ImageScale {
    tin: number;
    sig: number;
    col: number;
    year: number;
    ives: number;
}

export interface Offset {
    x: number;
    y: number;
    height: number;
    width: number;
}

export const defaultOff: Offset = {
    x: 0,
    y: 0,
    height: 0,
    width: 0,
};

export interface OffsetTypes {
    yearHolder: Offset;
    sigHolder: Offset;
    colHolder: Offset;
    tinHolder: Offset;
    ivesHolder: Offset
}

export const defaultOffsets: OffsetTypes = {
    colHolder: defaultOff,
    yearHolder: defaultOff,
    sigHolder: defaultOff,
    tinHolder: defaultOff,
    ivesHolder: defaultOff,
};
