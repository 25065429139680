import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { HotKeys } from "react-hotkeys";
import { Box, CircularProgress, Grid2, Modal, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import {
    TabPanel,
    TabContext,
    TabList,
} from "@mui/lab";
import { Buffer } from 'buffer';

import { AppDispatch, RootState } from "../../configureStore";
import { getReturnReviews, getReturns, saveReturns, SET_PDF } from "../../redux/reducer/returns";
import TaxReturn from "./TaxReturn";
import { ReturnReviews, UploadedReturn } from "../../interface/returns";
import Button from "../atoms/Button";
import globalStyles from "../../globalStyles";
import AppHeaderReturns from "../organisms/AppHeaderReturns";
import { ERROR_MESSAGE } from "../../redux/reducer/status";

interface TaxReturnsParams {
}

// const endpoint = process.env.REACT_APP_ENDPOINT || 'http://localhost:4000';

const TaxReturns = ({
}: TaxReturnsParams) => {
    const dispatch = useDispatch<AppDispatch>();

    const [params, setSearchParams] = useSearchParams();
    const loanNumber = params.get('loanNumber');
    const fiCode = params.get('fiCode');
    const { instance, accounts } = useMsal();
    const request = { scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE}/api`] };

    const pdfs = useSelector<RootState, UploadedReturn[]>(({ returns }) => returns.pdfs);
    const activePdf = useSelector<RootState, UploadedReturn | undefined>(({ returns }) => returns.pdf);
    const saving = useSelector<RootState, boolean>(({ status: { loading: l } }) => l['returns/SAVE_RETURNS']);
    const rrloading = useSelector<RootState, boolean>(({ status: { loading: l } }) => l['returns/GET_RETURN_REVIEWS']);
    const returnReviews = useSelector<RootState, ReturnReviews[]>(({ returns }) => returns.returnReviews);

    const [tab, setTab] = useState<number>(0);
    const [reauthed] = useState<number>(0);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);

    useEffect(() => {
        const getToken = () => {
            instance.acquireTokenSilent({
                ...request,
                account: accounts[0],
            }).then(({ accessToken, idToken }) => {
                localStorage.setItem('ACCESS_TOKEN', accessToken);
                localStorage.setItem('ID_TOKEN', idToken);
                window.dispatchEvent(new Event("token"));

                try {
                    localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => { console.error(err); });
        };
        getToken();
        const intervalId = setInterval(getToken, 300000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (pdfs[tab]?.name?.length) dispatch({ type: SET_PDF, pdfName: pdfs[tab].name });
    }, [pdfs, tab]);

    useEffect(() => {
        if (loanNumber && fiCode) dispatch(getReturns({ input: { loanNumber, fiCode } }));
        else {
            dispatch(getReturnReviews({
                onError: (rej) => {
                    if (rej.status === 419) {
                        instance.acquireTokenSilent({
                            ...request,
                            account: accounts[0],
                        }).then(({ accessToken, idToken }) => {
                            localStorage.setItem('ACCESS_TOKEN', accessToken);
                            localStorage.setItem('ID_TOKEN', idToken);
                            window.dispatchEvent(new Event("token"));

                            try {
                                localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
                            } catch (err) {
                                console.error(err);
                            }

                            dispatch(getReturnReviews({
                                onError: () => {
                                    dispatch({
                                        type: ERROR_MESSAGE,
                                        message: 'Authorization error, please try logging out.',
                                    });
                                },
                                overrideAuth: accessToken,
                            }));
                        }).catch((err) => { console.error(86, err); });
                    }
                },
            }));
        }
    }, [loanNumber, fiCode, reauthed]);

    const onSave = useCallback((finished: boolean) => {
        const copied = structuredClone(pdfs);
        const idx = copied.findIndex(({ name: fn }: UploadedReturn) => fn === activePdf?.name);
        if (idx > -1 && activePdf) copied[idx] = activePdf;

        if (loanNumber && fiCode) {
            const exp = localStorage.getItem('ACCESS_EXPIRY');
            if (exp && new Date(Number(exp)) < new Date()) {
                instance.acquireTokenSilent({
                    ...request,
                    account: accounts[0],
                }).then(({ accessToken, idToken }) => {
                    localStorage.setItem('ACCESS_TOKEN', accessToken);
                    localStorage.setItem('ID_TOKEN', idToken);
                    window.dispatchEvent(new Event("token"));

                    try {
                        localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
                    } catch (err) {
                        console.error(err);
                    }
                    dispatch(saveReturns({
                        input: {
                            pdfs: copied,
                            loanNumber,
                            fiCode,
                            finished,
                        },
                        onSuccess: () => {
                            setSaveOpen(false);
                            if (finished) {
                                const newParams = new URLSearchParams();
                                newParams.delete('fiCode');
                                newParams.delete('loanNumber');

                                setSearchParams(newParams, {
                                    preventScrollReset: true,
                                });
                            }
                        },
                    }));
                }).catch((err) => { console.error(err); });
            } else {
                dispatch(saveReturns({
                    input: {
                        pdfs: copied,
                        loanNumber,
                        fiCode,
                        finished,
                    },
                    onSuccess: () => {
                        setSaveOpen(false);
                        if (finished) {
                            const newParams = new URLSearchParams();
                            newParams.delete('fiCode');
                            newParams.delete('loanNumber');

                            setSearchParams(newParams, {
                                preventScrollReset: true,
                            });
                        }
                    },
                }));
            }
        }
    }, [
        pdfs,
        activePdf,
        loanNumber,
        fiCode,
        accounts,
    ]);

    const keyMap = {
        LEFT: ["left"],
        RIGHT: ["right"],
    };

    const handlers = {
        LEFT: () => {
            // LEFT: (_e: KeyboardEvent) => {
            document.getElementById("go-left")?.click();
        },
        RIGHT: () => {
            // RIGHT: (_e: KeyboardEvent) => {
            document.getElementById("go-right")?.click();
        },
    };

    return (
        <HotKeys keyMap={keyMap} handlers={handlers}>
            {(loanNumber && fiCode) ? (
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tab}>
                        <Box sx={[{ borderBottom: 1, borderColor: 'divider' }, globalStyles.flexCenterBetween]}>
                            <TabList
                                variant="scrollable"
                                scrollButtons="auto"
                                sx={{ width: '85%' }}
                                onChange={(_e, v) => {
                                    setTab(v);
                                }}
                            >
                                {pdfs.map(({ name }, idx) => (
                                    <Tab
                                        value={idx}
                                        key={`tab-${name}-${idx}`}
                                        label={(
                                            <Tooltip title={name}>
                                                <Typography maxWidth={160} noWrap>{name}</Typography>
                                            </Tooltip>
                                        )}
                                    />
                                ))}
                            </TabList>
                            <Modal
                                open={saveOpen}
                                onClose={() => { setSaveOpen(false); }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Typography>Is this completed or are you still working?</Typography>
                                    <Grid2 container spacing={2} sx={[globalStyles.mt2, globalStyles.flexEndEnd]}>
                                        <Grid2>
                                            <Button
                                                onClick={() => {
                                                    onSave(false);
                                                }}
                                            >
                                                Still working
                                            </Button>
                                        </Grid2>
                                        <Grid2>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    onSave(true);
                                                }}
                                            >
                                                Completed
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                </Box>
                            </Modal>
                            <Button
                                sx={globalStyles.mr1}
                                color="primary"
                                variant="contained"
                                loading={saving}
                                onClick={() => {
                                    setSaveOpen(true);
                                    // onSave();
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                        {pdfs.map((pdf, idx) => (
                            <TabPanel key={`tab-panel-${pdf.name}-${idx}`} value={idx}>
                                <TaxReturn />
                            </TabPanel>
                        ))}
                    </TabContext>
                </Box>
            ) : (
                <Box sx={{ flex: 1 }}>
                    <AppHeaderReturns />
                    <Box sx={[rrloading ? globalStyles.flexCenterCenter : globalStyles.flexStartCenter]}>
                        {
                            rrloading ? (
                                <CircularProgress />
                            ) : (
                                <TableContainer sx={{ width: 650 }} component={Paper}>
                                    <Table aria-label="return reviews">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Loan Number</TableCell>
                                                <TableCell align="right">Institution Code</TableCell>
                                                <TableCell align="right">Institution Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {returnReviews.map((row) => (!row ? (<div />) : (
                                                <TableRow
                                                    key={row.loanNumber}
                                                    sx={[{ '&:last-child td, &:last-child th': { border: 0 } }, !row?.inUse && globalStyles.pointer]}
                                                    onClick={(row.inUse && process.env.NODE_ENV !== 'development') ? () => { } : () => {
                                                        const newParams = new URLSearchParams();
                                                        newParams.set("loanNumber", row.loanNumber);
                                                        newParams.set("fiCode", row.fiCode);

                                                        setSearchParams(newParams, {
                                                            preventScrollReset: true,
                                                        });
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.loanNumber}
                                                    </TableCell>
                                                    <TableCell align="right">{row.institutionName}</TableCell>
                                                    <TableCell align="right">{row.institutionCode}</TableCell>
                                                </TableRow>
                                            )))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        }
                    </Box>
                </Box>
            )}
        </HotKeys>
    );
};

export default TaxReturns;
