import { Grid2 as Grid } from "@mui/material";
import { useSelector } from "react-redux";

import LeftColumn from "./organisms/LeftColumn";
import FormImages from "./organisms/FormImages";
import RightColumn from "./organisms/RightColumn";
import { RootState } from "../configureStore";

interface ConnectionParams {
}

const Connection = ({
}: ConnectionParams) => {
    const isMobile = useSelector<RootState, boolean>(({ status: { isMobile: im } }) => im || false);

    return (
        <Grid container spacing={2}>
            <Grid size={isMobile ? 12 : 2}>
                <LeftColumn />
            </Grid>
            <Grid size={isMobile ? 12 : 7} alignItems="center" justifyContent="center">
                <FormImages />
            </Grid>
            <Grid size={isMobile ? 12 : 3}>
                <RightColumn />
            </Grid>
        </Grid>
    );
};

export default Connection;
