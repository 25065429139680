import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Checkbox, Typography } from "@mui/material";

import globalStyles from "../../globalStyles";
import { renderUntoScreen } from "../../utils";
import { defaultOffsets } from "../../interface/ui";
import { RootState } from "../../configureStore";

interface FormNumberSelectorParams {
    pageNumber: number;
    rendered: boolean;
    retryRender: number;
    is4506: boolean;
    setIs4506: React.Dispatch<React.SetStateAction<boolean>>;
    pageRef: React.RefObject<HTMLDivElement>;
}

const FormNumberSelector = ({
    pageNumber,
    rendered,
    retryRender,
    is4506,
    setIs4506,
    pageRef,
}: FormNumberSelectorParams) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const list4506 = useSelector<RootState, { [pageNm: number]: boolean; }>(({ bus: { list4506: l } }) => l);

    useEffect(() => {
        renderUntoScreen({
            rendered,
            ref: pageRef,
            scaleTo: 1,
            innerRef,
            holderName: 'formnumHolder',
            timeout: 200,
            offset: defaultOffsets,
        });
    }, [pageNumber, pageRef, rendered, retryRender]);
    return (
        <Box
            sx={[
                globalStyles.flexCenterCenter,
                globalStyles.col,
                globalStyles.border,
                globalStyles.mb2,
                globalStyles.white,
                globalStyles.pa2,
                {
                    borderWidth: 2,
                    minHeight: 120,
                    zIndex: 200,
                },
            ]}
        >
            <div ref={innerRef} style={{ border: '1px solid lightgray' }}>
                <div id="formnumHolder" />
            </div>
            <Box sx={[globalStyles.flexCenterEnd, { alignSelf: 'flex-end' }]}>
                <Typography sx={{ fontSize: 15 }}>Is 4506-C</Typography>
                <Checkbox
                    checked={is4506}
                    onChange={(e) => { setIs4506(e.target.checked); }}
                    disabled={Object.values(list4506).some(Boolean) && !is4506}
                />
            </Box>
        </Box>
    );
};

export default FormNumberSelector;
