import { KeyLabel } from "../interface/returns";

export const formNames: (string | undefined)[] = [
    'Schedule C',
    'Schedule E',
    'Schedule F',
    'Form 8825',
    'Form 1065',
    'Form 1120S',
    'Form 1120',
    'Attachment',
    'Other',
];

export interface TaxForm {
    formName: string;
    formType: 'Business' | 'Individual';
    fields: KeyLabel[];
}

export const taxForms: { [formName: string]: TaxForm } = {
    'Schedule C': {
        formName: 'Schedule C',
        formType: 'Individual',
        fields: [
            { label: 'Social Security Number (SSN)', key: 'ssn' },
            { label: 'Employer ID number (EIN)', key: 'ein' },
            { label: 'Line 12: Depletion', key: 'Depletion' },
            { label: 'Line 44a: Business Miles', key: 'Business Miles' },
            { label: 'Amortization', key: 'Amortization' },
            { label: 'Casualty loss', key: 'Casualty loss' },
            { label: 'Field C: Business name', key: 'Business name' },
            { label: 'Field B: Code', key: 'NAICS Code' },
        ],
    },
    'Schedule E': {
        formName: 'Schedule E',
        formType: 'Individual',
        fields: [
            { label: 'Social Security Number', key: 'ssn' },
            { label: 'Address - A', key: 'Address' },
            { label: 'Type of Property - A', key: 'Type of Property' },
            { label: 'Fair Rental Days - A', key: 'Fair Rental Days' },
            { label: 'Line 3: Rents Received - A', key: 'Rents Received' },
            { label: 'Line 4: Royalties received - A', key: 'Royalties Received' },
            { label: 'Line 9: Insurance - A', key: 'Insurance' },
            { label: 'Line 12: Mortgage interest paid to banks, etc. - A', key: 'Mortage interest paid to banks' },
            { label: 'Line 16: Taxes - A', key: 'Taxes' },
            { label: 'Line 18: Depreciation Expense or depletion - A', key: 'Depreciation Expense or depletion' },
            { label: 'Line 19: Other List - Amortization - A', key: 'Amortization' },
            { label: 'Line 19: Other List - Casualty loss - A', key: 'Casualty loss' },
            { label: 'Line 19: Other List - HOA Dues - A', key: 'HOA Dues' },
            { label: 'Line 20: Total expenses - A', key: 'Total expenses' },
            { label: 'Address - B', key: 'Address' },
            { label: 'Type of Property - B', key: 'Type of Property' },
            { label: 'Fair Rental Days - B', key: 'Fair Rental Days' },
            { label: 'Line 3: Rents Received - B', key: 'Rents Received' },
            { label: 'Line 4: Royalties received - B', key: 'Royalties Received' },
            { label: 'Line 9: Insurance - B', key: 'Insurance' },
            { label: 'Line 12: Mortgage interest paid to banks, etc. - B', key: 'Mortage interest paid to banks' },
            { label: 'Line 16: Taxes - B', key: 'Taxes' },
            { label: 'Line 18: Depreciation Expense or depletion - B', key: 'Depreciation Expense or depletion' },
            { label: 'Line 19: Other List - Amortization - B', key: 'Amortization' },
            { label: 'Line 19: Other List - Casualty loss - B', key: 'Casualty loss' },
            { label: 'Line 19: Other List - HOA Dues - B', key: 'HOA Dues' },
            { label: 'Line 20: Total expenses - B', key: 'Total expenses' },
            { label: 'Address - C', key: 'Address' },
            { label: 'Type of Property - C', key: 'Type of Property' },
            { label: 'Fair Rental Days - C', key: 'Fair Rental Days' },
            { label: 'Line 3: Rents Received - C', key: 'Rents Received' },
            { label: 'Line 4: Royalties received - C', key: 'Royalties Received' },
            { label: 'Line 9: Insurance - C', key: 'Insurance' },
            { label: 'Line 12: Mortgage interest paid to banks, etc. - C', key: 'Mortage interest paid to banks' },
            { label: 'Line 16: Taxes - C', key: 'Taxes' },
            { label: 'Line 18: Depreciation Expense or depletion - C', key: 'Depreciation Expense or depletion' },
            { label: 'Line 19: Other List - Amortization - C', key: 'Amortization' },
            { label: 'Line 19: Other List - Casualty loss - C', key: 'Casualty loss' },
            { label: 'Line 19: Other List - HOA Dues - C', key: 'HOA Dues' },
            { label: 'Line 20: Total expenses - C', key: 'Total expenses' },
        ],
    },
    'Schedule F': {
        formName: 'Schedule F',
        formType: 'Individual',
        fields: [
            { label: 'Social Security Number', key: 'ssn' },
            { label: 'Line 5c: CCC loans taxable amount', key: 'CCC loans taxable amount' },
            { label: 'Line 8: Other Income', key: 'Other Income' },
            { label: 'Line 14: Depreciation', key: 'Depreciation' },
            { label: 'Line 32: Business use of home', key: 'Business use of home' },
            { label: 'Line 32: Amortization', key: 'Amortization' },
            { label: 'Line 32: Casualty loss', key: 'Casualty loss' },
            { label: 'Line 32: Depletion', key: 'Depletion' },
        ],
    },
    'Form 8825': {
        formName: 'Form 8825',
        formType: 'Business',
        fields: [
            { label: 'Name', key: 'Name' },
            { label: 'Employer identification number', key: 'ein' },
            { label: 'Line 1: Address - A', key: 'Address' },
            { label: 'Line 1: Address - B', key: 'Address' },
            { label: 'Line 1: Address - C', key: 'Address' },
            { label: 'Line 1: Address - D', key: 'Address' },
            { label: 'Line 1: Fair Rental Days - A', key: 'Fair Rental Days' },
            { label: 'Line 1: Fair Rental Days - B', key: 'Fair Rental Days' },
            { label: 'Line 1: Fair Rental Days - C', key: 'Fair Rental Days' },
            { label: 'Line 1: Fair Rental Days - D', key: 'Fair Rental Days' },
            { label: 'Line 16: Total expenses - A', key: 'Total expenses' },
            { label: 'Line 16: Total expenses - B', key: 'Total expenses' },
            { label: 'Line 16: Total expenses - C', key: 'Total expenses' },
            { label: 'Line 16: Total expenses - D', key: 'Total expenses' },
            { label: 'Line 7: Insurance - A', key: 'Insurance' },
            { label: 'Line 7: Insurance - B', key: 'Insurance' },
            { label: 'Line 7: Insurance - C', key: 'Insurance' },
            { label: 'Line 7: Insurance - D', key: 'Insurance' },
            { label: 'Line 11: Taxes - A', key: 'Taxes' },
            { label: 'Line 11: Taxes - B', key: 'Taxes' },
            { label: 'Line 11: Taxes - C', key: 'Taxes' },
            { label: 'Line 11: Taxes - D', key: 'Taxes' },
            { label: 'Line 2: Gross rents - A', key: 'Gross Rents Received' },
            { label: 'Line 2: Gross rents - B', key: 'Gross Rents Received' },
            { label: 'Line 2: Gross rents - C', key: 'Gross Rents Received' },
            { label: 'Line 2: Gross rents - D', key: 'Gross Rents Received' },
            { label: 'Line 14: Depreciation - A', key: 'Depreciation' },
            { label: 'Line 14: Depreciation - B', key: 'Depreciation' },
            { label: 'Line 14: Depreciation - C', key: 'Depreciation' },
            { label: 'Line 14: Depreciation - D', key: 'Depreciation' },
            { label: 'Line 15: Amortization - A', key: 'Amortization' },
            { label: 'Line 15: Amortization - B', key: 'Amortization' },
            { label: 'Line 15: Amortization - C', key: 'Amortization' },
            { label: 'Line 15: Amortization - D', key: 'Amortization' },
            { label: 'Line 15: Casualty loss - A', key: 'Casualty loss' },
            { label: 'Line 15: Casualty loss - B', key: 'Casualty loss' },
            { label: 'Line 15: Casualty loss - C', key: 'Casualty loss' },
            { label: 'Line 15: Casualty loss - D', key: 'Casualty loss' },
            { label: 'Line 15: HOA Dues - A', key: 'HOA Dues' },
            { label: 'Line 15: HOA Dues - B', key: 'HOA Dues' },
            { label: 'Line 15: HOA Dues - C', key: 'HOA Dues' },
            { label: 'Line 15: HOA Dues - D', key: 'HOA Dues' },
            { label: 'Line 15: Mortgage interest paid - A', key: 'Mortgage interest paid' },
            { label: 'Line 15: Mortgage interest paid - B', key: 'Mortgage interest paid' },
            { label: 'Line 15: Mortgage interest paid - C', key: 'Mortgage interest paid' },
            { label: 'Line 15: Mortgage interest paid - D', key: 'Mortgage interest paid' },
        ],
    },
    'Form 1065': {
        formName: 'Form 1065',
        formType: 'Business',
        fields: [
            { label: 'Name of partnership', key: 'Name' },
            { label: 'Employer identification number', key: 'ein' },
            { label: 'Schedule M-1 - Line 4b: Travel and entertainment exclusion', key: 'Schedule M-1.Travel and entertainment exclusion' },
            { label: 'Schedule L - Line 16d: Mortgages, notes, bonds payable in less than 1 year', key: 'Schedule L.Mortgages, notes, bonds payable in less than 1 year' },
            { label: 'Line 20: Other deductions (Attachment): Amortization', key: 'Attachment.Other deductions.Amortization' },
            { label: 'Line 20: Other deductions (Attachment): Casualty loss', key: 'Attachment.Other deductions.Casualty loss' },
        ],
    },
    'Form 1120S': {
        formName: 'Form 1120S',
        formType: 'Business',
        fields: [
            { label: 'Name', key: 'Name' },
            { label: 'Employer identification number', key: 'ein' },
            { label: 'Line 19: Other deductions (Attachment): Amortization', key: 'Attachment.Other deductions.Amortization' },
            { label: 'Line 19: Other deductions (Attachment): Casualty loss', key: 'Attachment.Other deductions.Casualty loss' },
            { label: 'Schedule M-1 - Line 3b: Travel and entertainment exclusion', key: 'Schedule M-1.Travel and entertainment exclusion' },
            { label: 'Schedule L - Line 16d: Mortgages, notes, bonds payable in less than 1 year', key: 'Schedule L.Mortgages, notes, bonds payable in less than 1 year' },
        ],
    },
    'Form 1120': {
        formName: 'Form 1120',
        formType: 'Business',
        fields: [
            { label: 'Name', key: 'Name' },
            { label: 'Employer identification number', key: 'ein' },
            { label: 'Line 26: Other deductions (Attachment): Amortization', key: 'Attachment.Other deductions.Amortization' },
            { label: 'Line 26: Other deductions (Attachment): Casualty loss', key: 'Attachment.Other deductions.Casualty loss' },
            { label: 'Schedule L - Line 17d: Mortgages, notes, bonds payable in less than 1 year', key: 'Schedule L.Mortgages, notes, bonds payable in less than 1 year' },
            { label: 'Schedule M-1 - Line 5c: Travel and entertainment exclusion', key: 'Schedule M-1.Travel and entertainment exclusion' },
        ],
    },
    Attachment: {
        formName: 'Attachment',
        formType: 'Individual',
        fields: [
            { label: 'Amortization', key: 'Attachment.Other deductions.Amortization', forms: ['1065', '1120S', '1120'] },
            { label: 'Casualty loss', key: 'Attachment.Other deductions.Casualty loss', forms: ['1065', '1120S', '1120'] },
        ],
    },
    Other: {
        formName: 'Other',
        formType: 'Individual',
        fields: [],
    },
};
