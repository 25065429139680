const initialState: {
    fortyFive: string[];
} = {
    fortyFive: [],
};

export const SET_FORTY_FIVE = 'institutions/SET_FORTY_FIVE';

type ActionParams =
    | { type: typeof SET_FORTY_FIVE; institutionCodes: string[]; }

// eslint-disable-next-line default-param-last
function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case SET_FORTY_FIVE:
            return {
                ...state,
                fortyFive: action.institutionCodes,
            };

        default: return state;
    }
}

export default reducer;
