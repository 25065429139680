import {
    Box,
    CircularProgress,
    Grid2 as Grid,
    Modal,
    Paper,
    Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { useEffect, useMemo, useState } from "react";
import { Document, Page } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../configureStore";
import { ADD_LINE_ITEM, ADD_REQUEST_LINE_ITEM, getAwaitingFax, getNextBorrower, REMOVE_BORROWER, saveFaxPdf } from "../../redux/reducer/fax";
import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";
import { FaxBorrower, FaxCoverLine, LineItems } from "../../interface/fax";
import Icon from "../atoms/Icon";
import ConfirmModal from "../molecules/ConfirmModal";
import { getTaxPeriodYear } from "../../utils";
import LineItem from "../molecules/LineItem";

interface Download4506Params {
}

const Download4506 = ({
}: Download4506Params) => {
    const dispatch = useDispatch<AppDispatch>();
    const { instance, accounts } = useMsal();
    const request = { scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE}/api`] };

    const awaitLoading = useSelector<RootState, boolean>(({ status: { loading: l, success: s } }) => (l['fax/GET_FAX_AWAITING'] && !s['fax/GET_FAX_AWAITING']));
    const loading = useSelector<RootState, boolean>(({ status: { loading: l } }) => (l['fax/GET_NEXT_BORROWER']));
    const loadingSave = useSelector<RootState, boolean>(({ status: { loading: l } }) => (l['fax/SAVE_FAX_PDF']));
    const removed = useSelector<RootState, string[]>(({ fax }) => fax.removed);
    const first = useSelector<RootState, string>(({ fax }) => fax.first);
    const borrowers = useSelector<RootState, FaxBorrower[]>(({ fax }) => fax.borrowers);
    const lineItems = useSelector<RootState, LineItems>(({ fax }) => fax.lineItems);

    const setOfLineIds = useMemo<Set<string>>(() => new Set(Object.keys(lineItems)), [lineItems]);

    const [pdfUrl, setPdfUrl] = useState<string>();
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [zoomOpen, setZoomOpen] = useState<boolean>(false);

    const seqClose = useMemo<string>(() => structuredClone(borrowers).reverse().find((brw: FaxBorrower) => !removed.includes(brw.sequenceNumber))?.sequenceNumber || '', [removed, borrowers]);

    useEffect(() => {
        const getToken = () => {
            instance.acquireTokenSilent({
                ...request,
                account: accounts[0],
            }).then(({ accessToken, idToken }) => {
                localStorage.setItem('ACCESS_TOKEN', accessToken);
                localStorage.setItem('ID_TOKEN', idToken);
                window.dispatchEvent(new Event("token"));

                try {
                    localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => { console.error(err); });
        };
        getToken();
        const intervalId = setInterval(getToken, 300000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        dispatch(getAwaitingFax({}));
    }, []);

    useEffect(() => {
        if (borrowers.length) setPdfUrl(borrowers[borrowers.length - 1]?.pdfB64);
    }, [borrowers.length]);

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    flex: 1,
                },
                awaitLoading ? globalStyles.flexCenterCenter : {},
            ]}
        >
            {
                awaitLoading ? <CircularProgress /> : (
                    <Grid sx={globalStyles.w100}>
                        <Grid container>
                            <Grid size={2} />
                            <Grid size={8}><Typography variant="h5">{Object.keys(lineItems).length} / 50 Line Items</Typography></Grid>
                            <Grid size={2}>
                                <Button
                                    disabled={Object.keys(lineItems).length === 0}
                                    variant="contained"
                                    loading={loadingSave}
                                    onClick={() => {
                                        dispatch(saveFaxPdf({
                                            input: {
                                                borrowers,
                                                lineItems,
                                                removed,
                                            },
                                            onSuccess: (response: AxiosResponse<{ pdf: string; fileName: string; }>) => {
                                                const byteCharacters = atob(response.data.pdf);
                                                const byteNumbers = new Array(byteCharacters.length);
                                                for (let i = 0; i < byteCharacters.length; i++) {
                                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                                }
                                                const byteArray = new Uint8Array(byteNumbers);
                                                const blob = new Blob([byteArray], { type: "application/pdf" });

                                                // Create a link and trigger a download
                                                const link = document.createElement("a");
                                                link.href = URL.createObjectURL(blob);
                                                link.download = response.data.fileName;
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            },
                                        }));
                                    }}
                                >
                                    Save Fax as PDF
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            sx={[{ overflowY: 'auto', height: (window.innerHeight * 0.78) }, globalStyles.my1]}
                        >
                            <Grid
                                size={6}
                                display="flex"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Box sx={[globalStyles.w100, globalStyles.px2]}>
                                    <Box sx={[globalStyles.flexStartStart, globalStyles.col]}>
                                        {borrowers.map((brwr) => (
                                            <Paper key={brwr.sequenceNumber} sx={[globalStyles.pa1, globalStyles.mb1, { width: '100%', display: removed.includes(brwr.sequenceNumber) ? 'none' : undefined }]}>
                                                <Grid container alignItems="center" justifyContent="space-between">
                                                    <Grid />
                                                    <Grid>
                                                        <Typography>{brwr.message.isBusiness ? brwr.message.businessName : `${brwr.message.firstName} ${brwr.message.lastName}`}</Typography>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid container>
                                                            <Grid>
                                                                <Icon name="FileOpen" onClick={() => { setPdfUrl(brwr.pdfB64); }} />
                                                            </Grid>
                                                            <Grid>
                                                                {(brwr.sequenceNumber === seqClose) && <Icon name="Close" onClick={() => { setConfirmOpen(true); }} />}
                                                                <ConfirmModal
                                                                    open={confirmOpen}
                                                                    setOpen={setConfirmOpen}
                                                                    text="Are you sure you want to not include this borrower? It will remove all line items associated with this instance."
                                                                    onConfirm={() => { dispatch({ type: REMOVE_BORROWER, sequenceNumber: brwr.sequenceNumber }); }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid size={6}>
                                                        {[...brwr.loan.borrowers, ...brwr.loan.businesses].map((loanBrwr) => (
                                                            <Paper key={`${brwr.sequenceNumber}_${loanBrwr.irsIncomeId}`} sx={[globalStyles.mb1, globalStyles.pap1, { backgroundColor: '#F0F0F0' }]}>
                                                                <Typography variant="h6" textAlign="left" sx={globalStyles.ml1}>{loanBrwr.type === 'Individual' ? `${loanBrwr.firstName} ${loanBrwr.lastName}` : loanBrwr.name}</Typography>
                                                                {loanBrwr.requests.map((req) => (
                                                                    <Grid
                                                                        key={req.requestId}
                                                                        container
                                                                        sx={[globalStyles.flexCenterBetween, globalStyles.mb1]}
                                                                    >
                                                                        <Grid size={2}>{req.taxPeriod}</Grid>
                                                                        <Grid size={6}>{req.form}</Grid>
                                                                        <Grid size={4}>
                                                                            <Button
                                                                                variant="contained"
                                                                                size="small"
                                                                                disabled={setOfLineIds.has(req.requestId)}
                                                                                onClick={() => {
                                                                                    const inItem: FaxCoverLine = {
                                                                                        name: loanBrwr.type === 'Individual' ? `${loanBrwr.lastName.toUpperCase().substring(0, 4)}/${loanBrwr.firstName.toUpperCase().substring(0, 1)}` : loanBrwr.name.toUpperCase().substring(0, 4),
                                                                                        formNumber: (req.form.startsWith('W') || req.form.startsWith('ALL')) ? 'WAID' : req.form.split(' - ')[0],
                                                                                        taxPeriod: getTaxPeriodYear(req.taxPeriod),
                                                                                        tin: loanBrwr.tin?.substring(5, 9) || '',
                                                                                    };
                                                                                    dispatch({ type: ADD_REQUEST_LINE_ITEM, request: req, item: inItem, sequenceNumber: brwr.sequenceNumber });
                                                                                }}
                                                                            >
                                                                                Add
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                            </Paper>
                                                        ))}
                                                    </Grid>
                                                    <Grid size={6}>
                                                        <Grid columnSpacing={2} rowSpacing={1} container sx={globalStyles.flexCenterStart}>
                                                            {Object.keys(lineItems).filter((lineKey) => lineItems[lineKey].sequenceNumber === brwr.sequenceNumber).map((key) => (
                                                                <LineItem
                                                                    key={`l-${key}`}
                                                                    id={key}
                                                                />
                                                            ))}
                                                            <Grid justifyItems="flex-end" justifySelf="flex-end">
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        dispatch({
                                                                            type: ADD_LINE_ITEM,
                                                                            tin: brwr.message.tin.substring(5, 9),
                                                                            name: brwr.message.isBusiness ? brwr.message.businessName?.substring(0, 4)?.toUpperCase() : `${brwr.message.lastName.substring(0, 4)?.toUpperCase()}/${brwr.message.firstName.substring(0, 1)?.toUpperCase()}`,
                                                                            sequenceNumber: brwr.sequenceNumber,
                                                                        });
                                                                    }}
                                                                >
                                                                    Add extra
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        ))}
                                        <Button
                                            variant="contained"
                                            sx={globalStyles.mt2}
                                            loading={loading}
                                            onClick={() => {
                                                dispatch(getNextBorrower({
                                                    input: {
                                                        last: borrowers.length ? borrowers[borrowers.length - 1].sequenceNumber : first,
                                                        type: borrowers.length === 0 ? 'first' : '',
                                                    },
                                                }));
                                            }}
                                        >
                                            Add next
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid size={6} display="flex" justifyContent="center" alignItems="flex-start">
                                <Paper
                                    sx={{
                                        p: 2,
                                        position: "sticky",
                                        top: 0,
                                    }}
                                >
                                    <Document
                                        file={pdfUrl}
                                    >
                                        <Page
                                            onClick={() => { setZoomOpen(true); }}
                                            pageNumber={1}
                                            height={(window.innerHeight * 0.78)}
                                        />
                                        <Modal
                                            open={zoomOpen}
                                            onClose={() => { setZoomOpen(false); }}
                                            sx={{ overflowY: 'scroll' }}
                                        >
                                            <Box
                                                sx={[globalStyles.flexCenterCenter, globalStyles.pointer, { overflowY: 'auto' }]}
                                            >
                                                <Page
                                                    onClick={() => { setZoomOpen(false); }}
                                                    pageNumber={1}
                                                    height={(window.innerHeight * 0.78 * 2)}
                                                />
                                            </Box>
                                        </Modal>
                                    </Document>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Box>
    );
};

export default Download4506;
