import { PublicClientApplication } from '@azure/msal-browser';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import store from "./configureStore";

import './App.css';
import { msalConfig } from './authConfig';
import OperationsApp from './OperationsApp';

function App() {
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.initialize();

    return (
        <MsalProvider instance={msalInstance}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Provider store={store}>
                    <OperationsApp />
                </Provider>
            </LocalizationProvider>
        </MsalProvider>
    );
}

export default App;
