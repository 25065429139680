import { useDispatch, useSelector } from "react-redux";
import { Grid2 as Grid, Input } from "@mui/material";

import { AppDispatch, RootState } from "../../configureStore";
import { ILineItem } from "../../interface/fax";
import Button from "../atoms/Button";
import { REMOVE_LINE_ITEM, UPDATE_LINE_ITEM } from "../../redux/reducer/fax";

interface LineItemParams {
    id: string;
}

const LineItem = ({
    id,
}: LineItemParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const item = useSelector<RootState, ILineItem>(({ fax }) => fax.lineItems[id]);

    return (
        <>
            <Grid size={3}>
                <Input
                    size="small"
                    value={item.item.name}
                    onChange={(e) => {
                        dispatch({ type: UPDATE_LINE_ITEM, id, value: e.target.value, keyName: 'name' });
                    }}
                />
            </Grid>
            <Grid size={2}>
                <Input
                    size="small"
                    value={item.item.tin}
                    onChange={(e) => {
                        dispatch({ type: UPDATE_LINE_ITEM, id, value: e.target.value, keyName: 'tin' });
                    }}
                />
            </Grid>
            <Grid size={2}>
                <Input
                    size="small"
                    value={item.item.taxPeriod}
                    onChange={(e) => {
                        dispatch({ type: UPDATE_LINE_ITEM, id, value: e.target.value, keyName: 'taxPeriod' });
                    }}
                />
            </Grid>
            <Grid size={2}>
                <Input
                    size="small"
                    value={item.item.formNumber}
                    onChange={(e) => {
                        dispatch({ type: UPDATE_LINE_ITEM, id, value: e.target.value, keyName: 'formNumber' });
                    }}
                />
            </Grid>
            <Grid size={2}>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        dispatch({ type: REMOVE_LINE_ITEM, id });
                    }}
                >
                    Remove
                </Button>
            </Grid>
        </>
    );
};

export default LineItem;
