import { useMsal } from "@azure/msal-react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import globalStyles from "../../globalStyles";
import IconMenu from "../atoms/IconMenu";
import Profile from "../molecules/Profile";
import { AppDispatch, RootState } from "../../configureStore";
import Icon from "../atoms/Icon";
import { textToClipboard } from "../../utils";
import { SUCCESSFUL_MESSAGE } from "../../redux/reducer/status";
import Button from "../atoms/Button";
import { RESET_REQUEST, SET_PROCESSING, SET_TOPIC, STOP_WAITING, Topic } from "../../redux/reducer/bus";
import { useWebSocketContext } from "../../WebSocketContext";
import TurnTimeModal from "../molecules/TurnTimeModal";

interface AppHeaderParams {
}

const AppHeader = ({
}: AppHeaderParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const { accounts } = useMsal();
    const irsIncomeId = useSelector<RootState, string | undefined>(({ bus }) => bus.request?.irsIncomeID);
    const issueCount = useSelector<RootState, number>(({ bus }) => bus.issueCount);
    const FICode = useSelector<RootState, string | undefined>(({ bus }) => bus.request?.FICode);
    const topic = useSelector<RootState, Topic>(({ bus }) => bus.topic);
    const processing = useSelector<RootState, boolean>(({ bus: { processing: pr } }) => pr);

    const [turnTimeOpen, setTurnTimeOpen] = useState<boolean>(false);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const { sendJsonMessage } = useWebSocketContext();

    return (
        <Box sx={[globalStyles.flexCenterBetween, globalStyles.pa1]}>
            <TurnTimeModal open={turnTimeOpen} setOpen={setTurnTimeOpen} />
            <Box sx={globalStyles.flexCenterBetween}>
                <Box
                    component="img"
                    sx={{
                        // height: 40,
                        width: 170,
                    }}
                    alt="Halcyon Still Water"
                    src="./logo.png"
                />
                {process.env.NODE_ENV === 'development'
                    && (
                        <Button
                            variant="outlined"
                            sx={globalStyles.mx2}
                            onClick={() => {
                                const audio = new Audio('/notif.wav');
                                audio.play();
                                dispatch({ type: RESET_REQUEST });
                            }}
                        >
                            Reset
                        </Button>
                    )}
                {
                    (topic === 'issue' || (topic === 'review' && issueCount > 0)) && (
                        <Button
                            variant="outlined"
                            sx={globalStyles.mx2}
                            onClick={() => {
                                if (topic === 'review') {
                                    if (processing) {
                                        sendJsonMessage({ command: 'deregister' });
                                    }
                                    dispatch({ type: SET_TOPIC, topic: 'issue' });
                                } else {
                                    dispatch({ type: SET_TOPIC, topic: 'review' });
                                }
                                dispatch({ type: SET_PROCESSING, processing: false });
                                dispatch({ type: STOP_WAITING });
                            }}
                        >
                            {topic === 'review' ? 'Issues' : 'Review'}
                        </Button>
                    )
                }
            </Box>
            <Box sx={globalStyles.flexCenterBetween}>
                {(irsIncomeId || FICode) && (
                    <>
                        <Typography
                            sx={{
                                // width: showInfo ? undefined : 0,
                                visibility: showInfo ? undefined : 'hidden',
                                opacity: showInfo ? 1 : 0,
                                animation: 'linear',
                                transition: 'opacity 600ms, visibility 600ms',
                            }}
                        >
                            FICode: {FICode} irsIncomeId: {irsIncomeId}
                        </Typography>
                        <Icon
                            name="Info"
                            onClick={() => {
                                if (!showInfo) {
                                    textToClipboard(JSON.stringify({ FICode, irsIncomeId }));
                                    setShowInfo(true);
                                    setTimeout(() => { setShowInfo(false); }, 5000);
                                    dispatch({ type: SUCCESSFUL_MESSAGE, message: 'Copied to clipboard.' });
                                } else {
                                    setShowInfo(false);
                                }
                            }}
                        />
                    </>
                )}
                <Box sx={globalStyles.flexCenterCenter}>
                    <Icon
                        name="Update"
                        onClick={() => { setTurnTimeOpen(true); }}
                    />
                    <Button
                        variant="outlined"
                        sx={globalStyles.mx2}
                        onClick={() => {
                            if (topic === 'review') {
                                if (processing) {
                                    sendJsonMessage({ command: 'deregister' });
                                }
                                dispatch({ type: SET_TOPIC, topic: 'download4506' });
                            } else {
                                dispatch({ type: SET_TOPIC, topic: 'review' });
                            }
                            dispatch({ type: SET_PROCESSING, processing: false });
                            dispatch({ type: STOP_WAITING });
                        }}
                    >
                        {topic === 'review' ? '4506-C' : 'Review'}
                    </Button>
                </Box>
                <IconMenu
                    name="AccountCircleOutlined"
                    item={(<Profile user={accounts[0]} />)}
                />
            </Box>
        </Box>
    );
};

export default AppHeader;
