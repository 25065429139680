/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import bus from "./bus";
import fax from "./fax";
import institutions from "./institutions";
import returns from "./returns";
import status from "./status";
import ui from "./ui";

export interface Payload<T = any> {
    onSuccess?: (data: any) => void;
    onError?: (data: any) => void;
    headers?: T;
    input?: T;
    data?: T;
    overrideAuth?: string;
    noLoad?: boolean;
}

const rootReducer = combineReducers({
    bus,
    fax,
    institutions,
    returns,
    status,
    ui,
});

export default rootReducer;
