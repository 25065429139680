// eslint-disable-next-line import/no-cycle
import { Payload } from ".";
import { AppDispatch } from "../../configureStore";
import execute from "../http/execute";
import failure from "../http/failure";
import { get, put } from "../http/request";
import success from "../http/success";

/* eslint-disable default-param-last */
const initialState: {
    turnTime?: string;
} = {
};

export const GET_TURN_TIMES_REQUEST = 'ui/GET_TURN_TIMES_REQUEST';
export const GET_TURN_TIMES_SUCCESS = 'ui/GET_TURN_TIMES_SUCCESS';
export const GET_TURN_TIMES_FAILURE = 'ui/GET_TURN_TIMES_FAILURE';

export const SET_TURN_TIMES_REQUEST = 'ui/SET_TURN_TIMES_REQUEST';
export const SET_TURN_TIMES_SUCCESS = 'ui/SET_TURN_TIMES_SUCCESS';
export const SET_TURN_TIMES_FAILURE = 'ui/SET_TURN_TIMES_FAILURE';

export const SET_TEMP_TURN_TIME = 'ui/SET_TEMP_TURN_TIME';

type ActionParams =
    | { type: typeof SET_TEMP_TURN_TIME; time: string; }
    | { type: typeof GET_TURN_TIMES_SUCCESS; data: { current: string; } };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case SET_TEMP_TURN_TIME: {
            return {
                ...state,
                turnTime: action.time,
            };
        }
        case GET_TURN_TIMES_SUCCESS: {
            return {
                ...state,
                turnTime: action.data.current,
            };
        }

        default: return state;
    }
}

export const getTurnTime = (payload: Payload<any>) => (dispatch: AppDispatch) => {
    dispatch({
        type: GET_TURN_TIMES_REQUEST,
        payload,
    });

    execute(get, `/turn-times`, payload.input, undefined, payload.overrideAuth)
        .then(success(dispatch, GET_TURN_TIMES_SUCCESS, payload))
        .catch(failure(dispatch, GET_TURN_TIMES_FAILURE, payload));
};

export const setTurnTime = (payload: Payload<{ current: string; }>) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_TURN_TIMES_REQUEST,
        payload,
    });

    execute(put, `/turn-times`, payload.input, undefined, payload.overrideAuth)
        .then(success(dispatch, SET_TURN_TIMES_SUCCESS, payload))
        .catch(failure(dispatch, SET_TURN_TIMES_FAILURE, payload));
};

export default reducer;
