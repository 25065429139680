import axios from 'axios';

const endpoint = process.env.REACT_APP_ENDPOINT || 'http://localhost:4000';
const ocpKey = process.env.REACT_APP_OCP_APIM_SUB_KEY;

export const get = (path: string, payload?: any, viewAs?: string, headers?: any, overrideAuth?: string) => {
    let params = '';
    if (Object.keys(payload ?? {}).length > 0) {
        params = '?';
        Object.keys(payload).forEach((key) => {
            params += `${key}=${payload[key]}&`;
        });
        params = params.substring(0, params.length - 1);
    }
    return axios.get(`${endpoint}${path}${params}`, {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers && { ...headers },
            Authorization: `Bearer ${overrideAuth || localStorage.getItem('ACCESS_TOKEN')}`,
            'OCP-APIM-Subscription-Key': ocpKey,
        },
    });
};

export const post = (path: string, payload: any, viewAs?: string, headers?: any, overrideAuth?: string) => axios.post(`${endpoint}${path}`, payload, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
            Authorization: `Bearer ${overrideAuth || localStorage.getItem('ACCESS_TOKEN')}`,
            'OCP-APIM-Subscription-Key': ocpKey,
        },
    },
});

export const postMulti = (path: string, data: FormData, headers?: any, overrideAuth?: string) => fetch(
    `${endpoint}${path}`,
    {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${overrideAuth || localStorage.getItem('ACCESS_TOKEN')}`,
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUB_KEY ?? '',
            ...headers,
        },
        body: data,
    },
);

export const patch = (path: string, payload: any, viewAs?: string, headers?: any) => axios.patch(`${endpoint}${path}`, payload, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
            'OCP-APIM-Subscription-Key': ocpKey,
        },
    },
});

export const put = (path: string, payload: any, viewAs?: string, headers?: any, overrideAuth?: string) => axios.put(`${endpoint}${path}`, payload, {
    headers: {
        ...viewAs && { 'X-View-As': viewAs },
        ...headers && { ...headers },
        Authorization: `Bearer ${overrideAuth || localStorage.getItem('ACCESS_TOKEN')}`,
        'OCP-APIM-Subscription-Key': ocpKey,
    },
});

export const del = (path: string, _: any, viewAs?: string, headers?: any) => axios.delete(`${endpoint}${path}`, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
            'OCP-APIM-Subscription-Key': ocpKey,
        },
    },
});

const request = (dispatch: any, type: string, payload: any) => {
    // eslint-disable-next-line no-param-reassign
    if (payload.onSuccess) delete payload.onSuccess;

    dispatch({
        type,
        payload,
    });
};

export default request;
