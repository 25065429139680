import { Box, Grid2, Modal, Typography } from "@mui/material";
import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";

interface ConfirmModalParams {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void;
    text?: string;
    noText?: string;
    yesText?: string;
}

const ConfirmModal = ({
    open,
    setOpen,
    onConfirm,
    text,
    noText,
    yesText,
}: ConfirmModalParams) => (
    <Modal
        open={open}
        onClose={() => { setOpen(false); }}
    >
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}
        >
            <Typography>{text || 'Are you sure?'}</Typography>
            <Grid2 container spacing={2} sx={[globalStyles.mt2, globalStyles.flexEndEnd]}>
                <Grid2>
                    <Button
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {noText || 'No'}
                    </Button>
                </Grid2>
                <Grid2>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onConfirm();
                            setOpen(false);
                        }}
                    >
                        {yesText || 'Yes'}
                    </Button>
                </Grid2>
            </Grid2>
        </Box>
    </Modal>
);

export default ConfirmModal;
