/* eslint-disable react/jsx-no-constructed-context-values */
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { HotKeys } from "react-hotkeys";
import { useMsal } from "@azure/msal-react";
import { Buffer } from 'buffer';

// eslint-disable-next-line import/no-cycle
import Connection from "../Connection";
import { AppDispatch, RootState } from "../../configureStore";
import { COUNT_SUCCESS, DONE_TEST, DOWNLOAD_SUCCESS, ISSUE_COUNT_SUCCESS, ISSUE_DOWNLOAD, ISSUE_RECEIVED, REASON_SUCCESS, REQUEST_COMPLETED, REQUEST_RECEIVED, SET_PRIORITY, TIMED_OUT, Topic } from "../../redux/reducer/bus";
import { SocketResponse } from "../../interface/message";
import Loading from "../Loading";
import globalStyles from "../../globalStyles";
import { WebSocketContext } from "../../WebSocketContext";
import AppHeader from "../organisms/AppHeader";
import ConnectionFailed from "./ConnectionFailed";
import Timeout from "../Timeout";
import Issues from "./Issues";
import { ERROR_MESSAGE, SUCCESSFUL_MESSAGE } from "../../redux/reducer/status";
import { SET_FORTY_FIVE } from "../../redux/reducer/institutions";
import Download4506 from "./Download4506";
import { priorityList } from "../../utils";

const endpoint = process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:4000";

const CheckOperations = ({ id }: { id: string; }) => {
    const isMobile = useSelector<RootState, boolean>(({ status: { isMobile: im } }) => im || false);
    const issLen = useSelector<RootState, number>(({ bus: { reasons } }) => Object.keys(reasons || {}).length);
    const topic = useSelector<RootState, Topic>(({ bus }) => bus.topic);

    const dispatch = useDispatch<AppDispatch>();
    const request = {
        scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE}/api`],
    };

    const [trying, setTrying] = useState<boolean>(true);
    const [token, setToken] = useState<string>(localStorage.getItem('ACCESS_TOKEN') || "");
    const [initial, setInitial] = useState<boolean>(true);
    const [tooMany, setTooMany] = useState<boolean>(false);
    const { instance, accounts } = useMsal();

    const timeout = useSelector<RootState, boolean>(({ bus: { timeout: to } }) => to);
    const cnt = useSelector<RootState, number>(({ bus: { count } }) => count);

    useEffect(() => {
        window.addEventListener('token', () => {
            setToken(localStorage.getItem('ACCESS_TOKEN') || "");
        });
        return () => {
            window.removeEventListener('token', () => {
                setToken(localStorage.getItem('ACCESS_TOKEN') || "");
            });
        };
    }, []);

    useEffect(() => {
        const exp = localStorage.getItem('ACCESS_EXPIRY');
        if (exp && new Date(Number(exp)) < new Date() && isMobile) {
            instance.acquireTokenSilent({
                ...request,
                account: accounts[0],
            }).then(({ accessToken, idToken }) => {
                localStorage.setItem('ACCESS_TOKEN', accessToken);
                localStorage.setItem('ID_TOKEN', idToken);
                window.dispatchEvent(new Event("token"));

                try {
                    localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => { console.error(err); });
        }
    }, []);

    useEffect(() => {
        if (accounts?.[0]) {
            dispatch({ type: SET_PRIORITY, hasAccess: priorityList.includes(accounts[0].username.toLowerCase()) });
        }
    }, [accounts]);

    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
    } = useWebSocket<SocketResponse>(
        endpoint,
        {
            protocols: ["Authorization", token, 'tab', id],
            share: true,
            shouldReconnect: () => !timeout,
            heartbeat: {
                message: 'ping',
                returnMessage: 'pong',
                timeout: 60000,
                interval: 25000,
            },
            onError: (errorEvent) => {
                console.error(errorEvent);
            },
            reconnectAttempts: 3,
            onReconnectStop: () => {
                setTrying(false);
            },
        },
    );

    useEffect(() => {
        if (lastJsonMessage === 419) {
            instance.acquireTokenSilent({
                ...request,
                account: accounts[0],
            }).then(({ accessToken, idToken }) => {
                localStorage.setItem('ACCESS_TOKEN', accessToken);
                localStorage.setItem('ID_TOKEN', idToken);
                window.dispatchEvent(new Event("token"));

                try {
                    localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => { console.error(err); });
        } else if (lastJsonMessage === 429) {
            setTooMany(true);
        } else if (lastJsonMessage) {
            switch (lastJsonMessage.type) {
                case 'issue-count':
                    dispatch({ type: ISSUE_COUNT_SUCCESS, count: lastJsonMessage.count });
                    break;
                case 'test-result':
                    dispatch({ type: DONE_TEST, id: lastJsonMessage.id });
                    if (lastJsonMessage.result) {
                        dispatch({
                            type: SUCCESSFUL_MESSAGE,
                            message: `Year: ${lastJsonMessage.taxYear} successfully authorized.`,
                        });
                    } else {
                        dispatch({
                            type: ERROR_MESSAGE,
                            message: lastJsonMessage.statusCode === 451 ? `Year: ${lastJsonMessage.taxYear} not authorized.` : `An error occurred. Status: ${lastJsonMessage.statusCode}. Case: ${lastJsonMessage.specialCase}`,
                        });
                    }
                    break;
                case 'count':
                    dispatch({ type: COUNT_SUCCESS, count: lastJsonMessage.count, priorityCount: lastJsonMessage.priorityCount });
                    if (lastJsonMessage.count < 0) sendJsonMessage({ command: "locked-check" });
                    break;
                case 'received':
                    sendJsonMessage({
                        command: "download",
                    });
                    dispatch({
                        type: REQUEST_RECEIVED,
                        FICode: lastJsonMessage.FICode,
                        irsIncomeId: lastJsonMessage.irsIncomeId,
                        tin: lastJsonMessage.tin,
                        businessName: lastJsonMessage.businessName,
                        firstName: lastJsonMessage.firstName,
                        lastName: lastJsonMessage.lastName,
                        institutionCode: lastJsonMessage.institutionCode,
                        loanNumber: lastJsonMessage.loanNumber,
                    });
                    break;
                case 'download':
                    dispatch({ type: DOWNLOAD_SUCCESS, b64: lastJsonMessage.encodedDownload });
                    new Audio('/notif.wav').play();
                    sendJsonMessage({ command: "count" });
                    break;
                case 'issue-received':
                    dispatch({
                        type: ISSUE_RECEIVED,
                        FICode: lastJsonMessage.FICode,
                        irsIncomeId: lastJsonMessage.irsIncomeId,
                        taxYears: lastJsonMessage.taxYears,
                        businessName: lastJsonMessage.businessName,
                        firstName: lastJsonMessage.firstName,
                        history: lastJsonMessage.history,
                        institutionCode: lastJsonMessage.institutionCode,
                        lastName: lastJsonMessage.lastName,
                        loanNumber: lastJsonMessage.loanNumber,
                        tin: lastJsonMessage.tin,
                        requests: lastJsonMessage.requests,
                        custFileNum: lastJsonMessage.custFileNum,
                        purposeType: lastJsonMessage.purposeType,
                        taxPayerType: lastJsonMessage.taxPayerType,
                    });
                    sendJsonMessage({
                        command: "issue-download",
                    });
                    break;
                case 'issue-download':
                    dispatch({ type: ISSUE_DOWNLOAD, b64: lastJsonMessage.encodedDownload });
                    new Audio('/notif.wav').play();
                    sendJsonMessage({ command: "issue-count" });
                    break;
                case 'reasons':
                    dispatch({ type: REASON_SUCCESS, reasons: lastJsonMessage.reasons });
                    break;
                case 'forty-five':
                    dispatch({ type: SET_FORTY_FIVE, institutionCodes: lastJsonMessage.institutionCodes });
                    break;
                case 'completed':
                    dispatch({ type: REQUEST_COMPLETED });
                    sendJsonMessage({ command: "count" });

                    break;
                case 'timeout':
                    new Audio('/error.wav').play();
                    dispatch({ type: TIMED_OUT });
                    sendJsonMessage({ command: "count" });

                    break;
                case 'finished':
                    dispatch({ type: REQUEST_COMPLETED });
                    sendJsonMessage({ command: "count" });

                    break;
                case 'goodnight':
                    instance.logout();

                    break;
                default:
                    // eslint-disable-next-line no-console
                    console.log(lastJsonMessage);
                    break;
            }
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        if (readyState === ReadyState.OPEN && initial) {
            setInitial(false);
            setTimeout(() => { sendJsonMessage({ command: 'count' }); }, 800);
            setTimeout(() => { sendJsonMessage({ command: 'issue-count' }); }, 800);
        }
    }, [readyState]);

    const isReady = useMemo<boolean>(() => (readyState === ReadyState.OPEN && cnt > -1), [cnt, readyState]);

    const keyMap = {
        LEFT: ["left"],
        RIGHT: ["right"],
        TIN: ["t"],
        FORM: ["f"],
        SIG: ["s"],
        SEND: ["c"],
        ERR: ["e"],
        ERR_BACK: ["shift+e"],
        NEXT: ["n"],
        STOP: ["p"],
    };

    const handlers = {
        LEFT: () => {
            document.getElementById("go-left")?.click();
        },
        RIGHT: () => {
            document.getElementById("go-right")?.click();
        },
        TIN: () => {
            document.getElementById("tin-check")?.click();
        },
        FORM: () => {
            document.getElementById("col-check")?.click();
        },
        SIG: () => {
            document.getElementById("sig-check")?.click();
        },
        SEND: () => {
            document.getElementById("complete-btn")?.click();
        },
        NEXT: () => {
            document.getElementById("next-btn")?.click();
        },
        STOP: () => {
            document.getElementById("stop-btn")?.click();
        },
        ERR: () => {
            const active = Array(issLen).findIndex((_, idx) => {
                const curr: HTMLInputElement | null = document.getElementById(`reason-${idx}`) as HTMLInputElement;
                return curr.checked;
            });

            if (active === issLen - 1) {
                document.getElementById(`reason-0`)?.click();
            } else {
                document.getElementById(`reason-${active + 1}`)?.click();
            }
        },
        ERR_BACK: () => {
            const active = Array(issLen).findIndex((_, idx) => {
                const curr: HTMLInputElement | null = document.getElementById(`reason-${idx}`) as HTMLInputElement;
                return curr.checked;
            });

            if (active === 0 || active === -1) {
                document.getElementById(`reason-${issLen - 1}`)?.click();
            } else {
                document.getElementById(`reason-${active - 1}`)?.click();
            }
        },
    };

    return (
        <HotKeys keyMap={keyMap} handlers={handlers}>
            <WebSocketContext.Provider
                value={{
                    sendMessage,
                    sendJsonMessage,
                    lastMessage,
                    lastJsonMessage,
                    readyState,
                    getWebSocket,
                }}
            >
                <Box
                    sx={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100vh",
                        color: "#000133",
                        backgroundColor: timeout ? 'red' : undefined,
                    }}
                >
                    <AppHeader />
                    {topic === 'review' ? (
                        <Box
                            sx={{
                                display: "flex",
                                flex: 1,
                                backgroundColor: timeout ? '#F05379' : undefined,
                            }}
                        >
                            <Box
                                component="main"
                                sx={[
                                    {
                                        flex: 1,
                                        background: "#f7f5f5",
                                        paddingBottom: 1,
                                        color: "black",
                                        backgroundColor: timeout ? '#F05379' : undefined,
                                    },
                                    globalStyles.px4,
                                    { ...isMobile ? globalStyles.pt1 : globalStyles.pt8 },
                                ]}
                            >
                                {timeout
                                    ? <Timeout />
                                    : tooMany
                                        ? <div>System is at capacity.</div>
                                        : trying
                                            ? isReady
                                                ? (
                                                    <Connection />
                                                )
                                                : <Loading />
                                            : <ConnectionFailed />}
                            </Box>
                        </Box>
                    ) : topic === 'issue' ? <Issues /> : <Download4506 />}
                    <Box sx={[globalStyles.darkBackground, { borderTop: '1px solid #0f0f0f' }, globalStyles.flexStartStart, globalStyles.py1]}>
                        Halcyon Operations Commander
                    </Box>
                </Box>
            </WebSocketContext.Provider>
        </HotKeys>
    );
};

export default CheckOperations;
